import styled from "styled-components";
import React from 'react';

import { WorkGrid } from './WorkGrid.js';
import { VerifyAction } from './verifyAction.js';

export const Cart = props => {
  const {cart, setCart} = props.state;
  return (
    <React.Fragment>
      <VerifyAction action={e => setCart([])} text="🗑️❌ tyhjennä työkori" />
      <WorkGrid works={cart} state={props.state} organizable={true}/>
    </React.Fragment>
  );
}

  // <p>Työkoriin voit kerätä töitä ja järjestellä niitä.
  // Se on täysin itsenäinen tallennetuista koreista, eikä se tallennu automaattisesti.
  // Vasta korihallinnassa tehdyt tallennusoperaatiot vaikuttavat tallennettuihin koreihin.
  // Voit järjestellä työkorin sisältöä laittamalla järjestelytilan päälle ja raahaamalla töitä hiirellä
  // uuteen järjestykseen.
  // </p>
