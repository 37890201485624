import styled from "styled-components";
import React from 'react';
var _ = require('lodash');

const ExContainer = styled.div`
`;

const Select = styled.select`
  font-size: 13pt;
  max-width: 248px;
`;

const MontageRow = styled.div`
  margin-top: 10pt;
`;

const TextInput = styled.input`
  font-size: 13pt;
`;

const Label = styled.div`
  font-size: 14pt;
  flex: 0 0 200px;
`;

const Button = styled.div`
  font-size: 11pt;
  border: 1px solid gray;
  padding: 3pt;
  border-radius: 3px;
  margin-right: 5pt;
  cursor: pointer;
`;


const Title = styled.div`
  flex: 1;
  font-size: 15pt;
  margin: .8em;
  cursor: pointer;

`;

const OptContainer = styled.div`
  margin: 1.5em;
`;

const OptRow = styled.div`
  display: flex;
  padding: .2em;
`;

const Page = styled.div`
  padding: 20pt;
`;

export const Montages = props => {

  const [montages, setMontages] = React.useState([]);
  const [newMontageBasket, setNewMontageBasket] = React.useState('');
  const [baskets, setBaskets] = React.useState([]);

  const fetchMontages = () => {
    fetch('/api/montages/list')
      .then(response => response.json())
      .then(data => {
        setMontages(data);
      });
    };

  const fetchBaskets = () => {
    fetch('/api/baskets/list')
      .then(response => response.json())
      .then(data => {
        setBaskets(data);
        setNewMontageBasket(data[0].basket_id);
      });
    };
  const createMontage = () => {
    fetch('/api/montages/create?basket_id=' + newMontageBasket)
      .then(response => response.json())
      .then(data => {
        fetchMontages();
      });
    };

  React.useEffect(() => {
    fetchMontages();
    fetchBaskets();
  }, []);

  return (
    <Page>
      <h1>Montaasit</h1>
      <Label>Uusi montaasi korista:</Label>
      <br/>
      <Select value={newMontageBasket} onChange={e => setNewMontageBasket(e.target.value)}>
        {baskets.map(t => <option key={t.basket_id} value={t.basket_id}>{t.name}</option>)}
      </Select>
      <br/>
      <br/>
      <button onClick={() => createMontage()}>➕ Uusi Montaasi</button><br/><br/><br/>
      {montages.map(m => <Montage key={m.montage_id}
                                  montage={m} baskets={baskets}
                                  fetchMontages={fetchMontages}
                                  />)}
    </Page>
  );
}

const Montage = props => {
  const montage = props.montage
  const fetchMontages = props.fetchMontages;
  const [basketId, setBasketId] = React.useState(montage.basket_id)
  const [size, setSize] = React.useState(montage.width)
  const [renderState, setRenderState] = React.useState(montage.render_state)
  const baskets = props.baskets;
  const [expanded, setExpanded] = React.useState(false);

  const deleteMontage = () => {
  fetch('/api/montages/delete?montage_id=' + montage.montage_id)
    .then(response => response.json())
    .then(data => {
      fetchMontages();
    });
  };

  const renderMontage = () => {
  setRenderState("rendering...")
  fetch('/api/montages/render?montage_id=' + montage.montage_id)
    .then(response => response.json())
    .then(data => {
      if (data.message === "rendered successfully") setRenderState("rendered");
      fetchMontages();
    });
  };

  const alterMontage = () => {
  fetch('/api/montages/alter?montage_id=' + montage.montage_id + '&basket_id=' + basketId + '&width=' + size + '&height=' + size)
    .then(response => response.json())
    .then(data => {
      fetchMontages();
    });
  };

  return (
    <>
      <Title onClick={() => {setExpanded(!expanded)}}> {props.montage.name}</Title>
      {expanded && <OptContainer>
      <OptRow>
        <Label>käytetty kori:</Label>
        <Select value={basketId} onChange={e => setBasketId(e.target.value)}>
          {baskets.map(t => <option key={t.basket_id} value={t.basket_id}>{t.name}</option>)}
        </Select>
      </OptRow>
      <OptRow>
        <Label>pikkukuvien koko:</Label>
        <TextInput value={size} onChange={e => setSize(e.target.value)}/>
      </OptRow>
      <OptRow>
        <Label>Renderöinnin tila</Label>
        <Label>{renderState} {renderState === 'rendered' && <a href={'https://arkisto.artcentre.fi/api/montages/get?montage_id=' + montage.montage_id}>linkki</a>}</Label>
      </OptRow>
      <OptRow>
        <Label></Label>
        <Button onClick={deleteMontage}>Poista</Button>
        <Button onClick={() => renderMontage()}>Renderöi</Button>
        {(size.toString() !== montage.width.toString() || basketId.toString() !== montage.basket_id.toString()) && <Button onClick={() => alterMontage()}>Tallenna muutokset</Button>}
      </OptRow>
      </OptContainer>}
    </>
  )
}
