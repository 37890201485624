import styled from "styled-components";
import React from 'react';
import {
  Redirect
} from "react-router-dom";

const Input = styled.input`
  font-size: 13pt;
`;
const Label = styled.label`
  flex: 0 0 200px;
  text-align: right;
  margin-right: 20px;
`;

const Button = styled.button`
	box-shadow:inset 0px 1px 0px 0px #d9fbbe;
	background:linear-gradient(to bottom, #b8e356 5%, #a5cc52 100%);
	background-color:#b8e356;
	border-radius:6px;
	border:1px solid #83c41a;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #86ae47;
`;

const H1 = styled.h1`
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: .5em;
`;

const Form = styled.form`
  z-index: 1101;
  border: 10px solid white;
`;

export const Login = props => {

  const [error, setError] = React.useState()
  const [success, setSuccess] = React.useState()


  const submit = async e => {
    e.preventDefault();
    setError(null)
    setSuccess(null)
    const body = {username: e.target.username.value,
                  password: e.target.password.value}
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    const res = await fetch('/api/user/login', requestOptions);
    const result = await res.json();
    const message = result.message;
    if (!res.ok) setError(message);
    else setSuccess(message);
    props.fetchSession();
  }
  return (
    <Form onSubmit={submit}>
      <Row>
        <Label/>
        <H1>Digiarkisto</H1>
      </Row>
      <Row>
        <Label>Käyttäjänimi:</Label>
        <Input type="text" name="username"/>
      </Row>
      <Row>
        <Label>Salasana:</Label>
        <Input type="password" name="password"/>
      </Row>
      {error &&
      <Row style={{color: 'red'}}>
        <Label></Label>
        {error}
      </Row>
      }
      {success &&
      <Row style={{color: 'green'}}>
        <Label></Label>
        {success}
      </Row>
      }
      <Row>
        <Label></Label>
        <Button type="submit">Kirjaudu sisään</Button>
      </Row>
      {success &&
        <Redirect to="/search"/>
      }
    </Form>
  );
};
