import styled from "styled-components";
import React from 'react';
import {
  useParams
} from "react-router-dom";

import { WorkGrid } from './WorkGrid.js';

export const ViewBasket = props => {
  const [works, setWorks] = React.useState([]);
  const [name, setName] = React.useState("");
  const {basketId} = useParams();
  const loadBasket = () => {
    fetch('/api/baskets/load?id=' + basketId)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setWorks(data);
      });
    };
  const fetchBaskets = () => {
    fetch('/api/baskets/list')
      .then(response => response.json())
      .then(data => {
        setName(data.find(e => e.basket_id == basketId).name);
      });
    };
  React.useEffect(() => {
    loadBasket();
    fetchBaskets();
  }, []);
  return (
    <React.Fragment>
      <h1>"{name}"</h1>
      <WorkGrid works={works} state={props.state} organizable={false}/>
    </React.Fragment>
  );
}
