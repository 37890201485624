import styled from "styled-components";
import React from 'react';

import { WorkGrid } from './WorkGrid.js';

import {useLocalStorage} from './localStorage.js';
var _ = require('lodash');

const Input = styled.input`
  font-size: 13pt;
  flex-grow: 1;
  &:disabled {
    background: #dddddd;
  }
  max-width: 400px;
  min-height: 30px;
  background: #fafafa;
  border-radius: 4px;
`;
const Label = styled.label`
  flex: 0 0 200px;
  text-align: right;
  margin-right: 20px;
`;

const Select = styled.select`
  min-height: 30px;
  font-size: 13pt;
  flex-grow: 1;
  &:disabled {
    background: #dddddd;
  }
  max-width: 408px;
`;

const Button = styled.button`
  display: block;
  font-size: 13pt;
`;

const TermsWrapper = styled.form`
  background-color: whitesmoke;
  list-style-type: none;
  padding: 0;
  border-radius: 3px;
`;
const TermsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: .5em;
  align-items: center;
`;

const SearchTerm = props => {
  const term = props.term;

  const hChange = (event) => {
    var newState = _.cloneDeep(props.searchState)
    newState.terms = newState.terms.map(e => {
        if (e.term === term.term) {
          return {term: e.term, desc: term.desc, value: event.target.value}
        } else {
          return e;
        }
      })
    props.setSearchState(newState);
  }

  return (
    <TermsRow>
      <Label>{term.desc}</Label>
      {props.type === 'text' ?
        <Input disabled={props.disabled} type='text' value={term.value} onChange={hChange} />
        :
        <Select disabled={props.disabled} value={term.value} onChange={hChange}>
          <option value=''>-</option>
        {props.selection.map(e =>
          <option key={e} value={e}>{e}</option>
        )}
        </Select>
      }
    </TermsRow>
  );
};

const PageChooserContainer = styled.div`
  padding: .5em;
`;

const PageChooser = props => {
  const {offset, count} = props.searchState;
  const perPage = 100;
  const pages = Math.ceil(count/perPage);
  const curPage = Math.round(offset/perPage);
  const setOffset = v => {
    var newState = _.cloneDeep(props.searchState);
    newState.offset = v;
    newState.fetchNeeded = true;
    props.setSearchState(newState);
  }
  const hPageChange = (e) => {
    setOffset(e.target.value);
  }
  return (
    <PageChooserContainer>
      <strong>{count}</strong> hakutulosta.
      {count > 0 && <label> Näytetään tulosten sivu </label>}
      <select value={offset} onChange={hPageChange}>
      {Array(pages).fill().map((_,i) => i*perPage).map((o,i) =>
        <option key={o} value={o}>{i}</option>
      )}
      </select>
      <span style={{cursor:'pointer', marginLeft: 10, textDecorationLine: 'underline', textDecorationStyle: 'dashed'}} onClick={e => setOffset((curPage + 1) * perPage)}>seuraava sivu </span>
    </PageChooserContainer>
  );
};


export const Search = props => {
  const [countries, setCountries] = React.useState([]);
  const [collections, setCollections] = React.useState([]);
  const terms = [ {term: 'freeterm', desc: '❓Vapaa haku', value: ''},
                  {term: 'age', desc: '🔢 Ikä', value: ''},
                  {term: 'author', desc: '🧍 Tekijä', value: ''},
                  {term: 'keyword', desc: '🗝️ Asiasana (,)', value: ''},
                  {term: 'competition', desc: '🏆 Ikäkategoria -kenttä (,)', value: ''},
                  {term: 'technique', desc: '🎨 Tekniikka -kenttä (,)', value: ''},
                  {term: 'country', desc: '🌍 Maa', value: ''},
                  {term: 'archival_number', desc: '🗄️ Arkistointinumero', value: ''},
                  {term: 'archive_location_category', desc: '🗄️ Säilytyspaikan tunnus', value: ''},
                  {term: 'collection', desc: '🗄️ Kokoelma', value: ''} ]
  const [searchState, setSearchState] = useLocalStorage('searchState3',
                {terms: terms,
                offset: 0,
                result: [],
                count: 0,
                fetchNeeded: true});
  const fetchSearch = () => {
    var params = searchState.terms.reduce((a,e) => {
          a[e.term] = e.value;
          return a;
        }, {});
    params.offset = searchState.offset;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };
    fetch('/api/search', requestOptions)
        .then(response => response.json())
        .then(data => {
          var newState = _.cloneDeep(searchState);
          newState.count = data.count;
          newState.result = data.result;
          newState.fetchNeeded = false;
          setSearchState(newState);
        })
    };
  const fetchCountriesAndCollections = () => {
      fetch('/api/countries_and_collections')
        .then(response => response.json())
        .then(data => {
          setCountries(data.countries);
          setCollections(data.collections);
        });
      };
  React.useEffect(() => {
    fetchCountriesAndCollections();
  }, []);
  React.useEffect(() => {
    if (searchState.fetchNeeded)
       fetchSearch();
  }, [searchState]);

  const hSearchButtonClick = (e) => {
      e.preventDefault()
      var newState = _.cloneDeep(searchState);
      newState.offset = 0;
      newState.fetchNeeded = true;
      setSearchState(newState);
  };
  return (
    <div>
      <TermsWrapper onSubmit={hSearchButtonClick}>
      {searchState.terms.map(e =>
        <SearchTerm key={e.term}
                    term={e}
                    searchState={searchState}
                    setSearchState={setSearchState}
                    disabled={e.term !== 'freeterm' && searchState.terms[0].value !== ''}
                    type={e.term === 'country' || e.term === 'collection' || e.term === 'archive_location_category' ? 'select' : 'text'}
                    selection={(e => {
                      switch(e.term) {
                        case 'collection':
                          return collections
                        case 'country':
                          return countries;
                        case 'archive_location_category':
                          return ['A','B','C','D'];
                      }
                    })(e)}
                    />
      )}
      <TermsRow>
      <Label/>
      {!searchState.fetchNeeded ?
        <Button type="submit" onSubmit={hSearchButtonClick}>Hae</Button>
        :
        <Button>Haetaan...</Button>
      }
      </TermsRow>
      </TermsWrapper>
      <PageChooser searchState={searchState} setSearchState={setSearchState}/>
      <WorkGrid state={props.state} works={searchState.result}/>
      <PageChooser searchState={searchState} setSearchState={setSearchState}/>
    </div>
  );
};
