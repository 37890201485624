import React from 'react';
import {
  Redirect
} from "react-router-dom";

export const Logout = props => {

  const [ok, setOk] = React.useState(null);

  const doLogout = async () => {
    await fetch('/api/user/logout');
    setOk('ok');
    props.fetchSession()
  };

  React.useEffect(() => {
    setOk(null);
    doLogout();
  }, []);
  return (
    <div>
      {ok &&
        <Redirect to="/login"/>
      }
    </div>
  );
};
