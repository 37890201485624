import styled from "styled-components";
import React from 'react';
import { Link } from "react-router-dom";

const NavBar = styled.div`
  background-color: #fefefe;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 2px solid gray;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  box-sizing: border-box;
  z-index: 1000;
`;

const NavItem = styled.a`
  color: #006600;
  text-decoration: none;
  cursor: pointer;
`;

const NavLink = styled(Link)`
  color: #006600;
  text-decoration: none;
`;

const Float = styled(Link)`
  position: fixed;
  top: 30px;
  right: 20px;
  background-color: white;
  padding: 10px;
`;

const Spacer = styled.div`
  height: 50px;
`;

const LogInfo = props => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div>
      <NavItem onClick={e => setExpanded(!expanded)}>
        Kirjautuneena: {props.user}
      </NavItem>
      {expanded &&
        <Float>
        <NavLink to="/logout">🛑 Logout</NavLink><br/><br/>
        <NavLink to="/user">🔑 Vaihda salasana</NavLink>
        </Float>
      }
    </div>
  );
}

export const Navi = props => {
  const {cart, setCart, user, permissions} = props.state;
  return (
    <>
    <NavBar>
      <NavLink to="/search">🔍 haku</NavLink>
      <NavLink to="/cart">🧺 työkori({cart.length})</NavLink>
      <NavLink to="/baskets">⛏️ korihallinta</NavLink>
      <form method="post" action="/api/printout">
        <input type="hidden" name="ids" value={cart.join(',')}/>
        <button type="submit">
          🖨️ printout
        </button>
      </form>
      <form method="post" action="/api/zipcart.zip">
        <input type="hidden" name="ids" value={cart.join(',')}/>
        <button type="submit">
          🗜️ zip
        </button>
      </form>
      {permissions.write && <NavItem onClick={() => window.open('/upload')}>📤 Lisää kuva</NavItem> }
      {permissions.write && <NavLink to="/exhibitions">🖼️ Näyttelyt</NavLink> }
      {permissions.write && <NavLink to="/view_exhibitions">🖼️ Näyttelyt esikatselu</NavLink> }
      {permissions.write && <NavLink to="/montages">🎨 Montaasit</NavLink> }
      <LogInfo user={user} />
    </NavBar>
    <Spacer/>
    </>
  );
}
