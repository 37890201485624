import './App.css';
import React from 'react';
import {ViewExhibitions} from './ViewExhibitions.js'
import {Exhibitions} from './Exhibitions.js'
import {Baskets} from './Baskets.js'
import {ViewBasket} from './ViewBasket.js'
import {Navi} from './Navi.js'
import {Cart} from './Cart.js'
import {Search} from './Search.js'
import {User} from './User.js'
import {Login} from './Login.js'
import {Logout} from './Logout.js'
import {Work} from './Work.js'
import {Montages} from './Montages.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect
} from "react-router-dom";
import styled from "styled-components";
import {useLocalStorage} from './localStorage.js';


function App() {
  const [user, setUser] = React.useState('');
  const [permissions, setPermissions] = React.useState({write: false});
  const [cart, setCart] = useLocalStorage('cart', []);

  const fetchSession = () => {
    fetch('/api/session')
      .then(response => response.json())
      .then(data => {
        setUser(data.user);
        setPermissions(data.permissions);
      });
  };
  React.useEffect(() => {
    fetchSession();
  }, []);

  const state = {cart: cart, setCart: setCart, user: user, permissions: permissions}
  function HeaderView() {
    const location = useLocation();
    console.log(location.pathname);
    return <span>Path : {location.pathname}</span>
  }
  return (
    <Router>
     {user !== '' ?
      <Switch>
       <Route path="/arkisto/verkkonayttelyt/nayttelyt/">
          <ViewExhibitions/>
        </Route>
       <Route path="/login">
          <Login fetchSession={fetchSession}/>
        </Route>
       <Route path="/logout">
          <Logout fetchSession={fetchSession}/>
        </Route>
       <Route path="/view_exhibitions">
          <ViewExhibitions/>
        </Route>
       <Route path="/montages">
          <Navi state={state}/>
          <Montages state={state}/>
        </Route>
       <Route path="/baskets">
          <Navi state={state}/>
          <Baskets state={state}/>
        </Route>
       <Route path="/viewbasket/:basketId">
          <Navi state={state}/>
          <ViewBasket state={state}/>
        </Route>
       <Route path="/search">
          <Navi state={state}/>
          <Search state={state}/>
        </Route>
       <Route path="/cart">
          <Navi state={state}/>
          <Cart state={state}/>
        </Route>
       <Route path="/Exhibitions">
          <Navi state={state}/>
          <Exhibitions state={state}/>
        </Route>
       <Route path="/work/:workId">
          <Navi state={state}/>
          <Work state={state}/>
        </Route>
       <Route path="/user">
          <Navi state={state}/>
          <User state={state}/>
        </Route>
       <Route path="/">
          <Redirect to='/search'/>
        </Route>
      </Switch>
      :
      <Switch>
       <Route path="/arkisto/verkkonayttelyt/nayttelyt/">
          <ViewExhibitions/>
        </Route>
       <Route path="/">
          <Login fetchSession={fetchSession}/>
        </Route>
      </Switch>
      }
    </Router>
  );
}

export default App;
