import styled, { keyframes } from "styled-components";
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ExhibitionTitle = styled.h1`
  font-size: 32px;
  color: #1fb2ab;
  padding: 0 0 15px;
  font-family: 'Lato', sans-serif;
  line-height: 1.3;
`;

const ExhibitionText = styled.div`
  font-family: 'Helvetica Neue', Helvetica;
  font-size: 15px;
  padding: 10px 0 15px;
  color: #333;
  line-height: 1.3;
`;

const WorkImg = styled.div`
  background-color: white;
  display: block;
  float: left;
  border: 8px solid #fff;
  box-shadow: 0 1px 3px #CCCCCC;
  height: 145px;
  width: 145px;
  margin: 0 4px 10px;
  background: center center no-repeat url('https://arkisto.artcentre.fi/ajax-loader_145.gif');
  cursor: pointer;
`;

const WorksTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding: 0 0 30px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  color: #333;
  line-height: 1.3;
`;

const Hr = styled.hr`
  padding-top: 20px;
  margin-bottom: 20px;
  border: 0px;
  border-bottom: 1px solid #a0a3a7;
`;



const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
`;
const Column = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 8px;
  @media screen and (min-width: 800px) {
    flex: 1;
  }
`;
const MenuColumn = styled(Column)`
  max-width: 250px;
`;
const ExhibitionLi = styled.li`
  list-style-type: none;
  font-family: 'Lato', sans-serif;
  background: no-repeat 0px 5px url('https://arkisto.artcentre.fi/li_arrow.png');
  padding-left: 16px;
  margin-bottom: 10px;
  margin-top: 5px;
  cursor: pointer;
  font-style: italic;
  color: #6f6f6f;
  color: ${props => props.focused ? "#F08200" : "#6f6f6f"};
`;
const ExhibitionUl = styled.ul`
  margin: 0px;
    padding: 0px;
`;

const H2 = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  padding: 15px 0 15px;
  color: #333;
  line-height: 1.3;
`;

const exhibitionSortFn = (a, b) => {
   return parseInt("000" + a.exhibition_title.split(".")[0]) > parseInt("000" + b.exhibition_title.split(".")[0]);
}

const apiUrl = 'https://arkisto.artcentre.fi'

export const ViewExhibitions = (props) => {
  const [selectedExhibition, setSelectedExhibition] = React.useState("");
  const [selectedExhibitionCategory, setSelectedExhibitionCategory] = React.useState(1);
  const [exhibitions, setExhibitions] = React.useState([]);
  const [exhibitionCategories, setExhibitionCategories] = React.useState([]);
  const fetchExhibitions = () => {
    fetch(apiUrl + '/api/view_exhibitions/list')
      .then(response => response.json())
      .then(data => {
        setExhibitions(data);
      });
    };
  const fetchExhibitionCategories = () => {
    fetch(apiUrl + '/api/view_exhibitions/exhibition_categories/list')
      .then(response => response.json())
      .then(data => {
        setExhibitionCategories(data);
      });
    };
  React.useEffect(() => {
    fetchExhibitions();
    fetchExhibitionCategories();
  }, []);
  return (
    <Row>
      <MenuColumn>
        <H2>NÄYTTELYT</H2>
        <ExhibitionUl>
          {exhibitionCategories.map(c =>
            <ExhibitionLi onClick={(_) => {setSelectedExhibitionCategory(c.exhibition_category_id)}} key={c.exhibition_category_id} focused={c.exhibition_category_id === selectedExhibitionCategory}>
              <div onClick={(_) => {setSelectedExhibition("")}}>{c.exhibition_category_name}</div>
              <ExhibitionUl>
                {exhibitions.sort(exhibitionSortFn).filter(e => e.exhibition_category_id === c.exhibition_category_id).map(e =>
                  <ExhibitionLi focused={e.exhibition_id === selectedExhibition} key={e.exhibition_id} onClick={(_) => {setSelectedExhibition(e.exhibition_id)}}>
                    {e.exhibition_title}
                  </ExhibitionLi> )}
              </ExhibitionUl>
            </ExhibitionLi> )}
        </ExhibitionUl>
      </MenuColumn>
      <Column>
        {selectedExhibition === "" && <ViewExhibitionCategory id={selectedExhibitionCategory} setSelectedExhibition={setSelectedExhibition}/>}
        {selectedExhibition !== "" && <ViewExhibition id={selectedExhibition}/>}
      </Column>
    </Row>
  );
}

const ViewExhibitionCategory = (props) => {
  const id = props.id;
  const setSelectedExhibition = props.setSelectedExhibition
  const [title, setTitle] = React.useState("");
  const [exhibitions, setExhibitions] = React.useState([]);

  const fetchExhibitionCategory = () => {
    fetch(apiUrl + '/api/view_exhibitions/exhibition_categories/info/' + id)
      .then(response => response.json())
      .then(data => {
        setTitle(data.exhibition_category_name);
      });
    };
  const fetchExhibitions = () => {
      fetch(apiUrl + '/api/view_exhibitions/exhibition_categories/exhibitions_for/' + id)
        .then(response => response.json())
        .then(data => {
          setExhibitions(data.sort(exhibitionSortFn));
        });
      };
  React.useEffect(() => {
    fetchExhibitionCategory();
    fetchExhibitions();
  }, [id]);

  const ExhibitionInfo = styled.span`
    background-color: white;
    vertical-align: text-top;
    display: inline-block;
    border: 8px solid #fff;
    margin: 5px;
    box-shadow: 0 1px 3px #CCCCCC;
    margin-bottom: 10px;
    cursor: pointer;
  `;
  const ExImg = styled.span`
  `;
  const ExTitle = styled.h4`
    font-size: 14px;
    font-weight: 700;
    margin: 4px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    width: 180px;
  `;
  return (
    <div>
      <ExhibitionTitle>{title}</ExhibitionTitle>
      {exhibitions.map((e) => <ExhibitionInfo key={e.exhibition_id} onClick={() => {setSelectedExhibition(e.exhibition_id)}}>
                                <ExImg>
                                  <LazyLoadImage height="180" width="180" src={apiUrl + '/api/view_exhibitions/square_thumb/' + e.cover_work_id}/>
                                </ExImg>
                                <ExTitle>{e.exhibition_title}</ExTitle>
                              </ExhibitionInfo>
      )}
    </div>
  );
}

const ViewExhibition = (props) => {
  const id = props.id;
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [works, setWorks] = React.useState([]);
  const [focusedWork, setFocusedWork] = React.useState("");

  const nextWork = () => {
    let index = (works.indexOf(focusedWork)+1) % works.length;
    setFocusedWork(works[index]);
  }
  const previousWork = () => {
    let index = (works.indexOf(focusedWork)+works.length-1) % works.length;
    setFocusedWork(works[index]);
  }

  const fetchExhibition = () => {
    if (!id) return;
    fetch(apiUrl + '/api/view_exhibitions/exhibition_info/' + id)
      .then(response => response.json())
      .then(data => {
        setTitle(data.title);
        setText(data.text);
        setWorks(data.works);
      });
    };
  React.useEffect(() => {
    fetchExhibition();
  }, [id]);


  return (
    <div>
      <ExhibitionTitle>
        {title}
      </ExhibitionTitle>
      <ExhibitionText dangerouslySetInnerHTML={{__html: text}} />
      <Hr/>
      <WorksTitle>Näyttelyn teokset</WorksTitle>

      {works.map((w) => <WorkImg key={w}>
                          <LazyLoadImage height="145" width="145" src={apiUrl + '/api/view_exhibitions/square_thumb/' + w} onClick={() => {setFocusedWork(w)}}/>
                        </WorkImg>)}
      {false && works.map((w) => <WorkImg src={apiUrl + '/api/view_exhibitions/square_thumb/' + w} key={w} onClick={() => {setFocusedWork(w)}}/>)}
      {focusedWork !== "" &&
      <FocusedWork id={focusedWork} setFocusedWork={setFocusedWork} nextWork={nextWork} previousWork={previousWork}>

      </FocusedWork>
      }
    </div>
  );
}

const Blanket = styled.div`
  background-color: rgb(119, 119, 119);
  display: block;
  opacity: 0.7;
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  pointer-events: none;
  display: block;
  width: 80px;
  height: 80px;
  z-index: 1110;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${spin} 1.2s linear infinite;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1101;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 10px solid white;
`;

const FocusedImage = styled.img`
  display : block;
  min-width:90vmin;
  min-height:90vmin;
  width: 100%;
  max-height: 90vmin;
`;

const Caption = styled.div`

    background: rgba(0,0,0,0.99);
    color: #fff;
    padding: 10px;
`;

const Next = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
    height: 90%;

    background: rgba(0,0,0,0.7);
    opacity: 0;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.3;
    }
    text-align: right;
`;
const Previous = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30%;
    height: 90%;

    background: rgba(0,0,0,0.7);
    opacity: 0;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.3;
    }
`;

const FocusedWork = (props) => {
  const id = props.id;
  const setFocusedWork = props.setFocusedWork;
  const nextWork = props.nextWork;
  const previousWork = props.previousWork;

  const [title, setTitle] = React.useState("");
  const [name, setName] = React.useState("");
  const [age, setAge] = React.useState("");
  const [year, setYear] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const fetchCaption = () => {
    fetch(apiUrl + '/api/view_exhibitions/work_caption/' + id)
      .then(response => response.json())
      .then(data => {
        setTitle(data[0].work_title);
        setName(data[0].artist_name);
        setAge(data[0].artist_age);
        setYear(data[0].work_year);
        setCountry(data[0].work_country);
      });
    };
  React.useEffect(() => {
    fetchCaption();
  }, [id]);

  return (
    <React.Fragment>
      <Blanket onClick={() => {setFocusedWork("")}}/>
      {loading && <Spinner/>}
      <Popup>
        <FocusedImage src={apiUrl + '/api/view_exhibitions/img/' + id} onLoad={_ => setLoading(false)}/>
        <Caption>
          {title}, {name}, {age}v, {year}, {country}
        </Caption>

        <Next onClick={() => {nextWork(); setLoading(true)}}>
          {">"}
        </Next>
        <Previous onClick={() => {previousWork(); setLoading(true)}}>
          {"<"}
        </Previous>
      </Popup>
    </React.Fragment>
  );
}
