import styled from "styled-components";
import React from 'react';
import Draggable from 'react-draggable';
import { Link } from "react-router-dom";

import { LazyLoadImage } from 'react-lazy-load-image-component';

const ResultContainer = styled.div`
  width: 200px;
  height: 200px;
  display: inline-block;
  text-align: right;
  margin: 1px;
  position: relative;
  ${props => props.drag ? 'z-index:99' : ''}
`;
const ResultImg = styled(LazyLoadImage)`
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  z-index: 0;
`;
const Inspect = styled(Link)`
  text-align: center;
  background-color: rgba(0,0,255,0.02);
  color: rgba(0,0,0,0.2);
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 7px;
`;

const Check = styled.div`
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 7px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${props => props.in_cart ? "rgba(0,255,0,1)" : "rgba(0,0,0,0.1)"}
`;

const PopOver = styled.div`
  pointer-events: none;
  color: black;
  display: block;
  background: #C8C8C8;
  opacity: 1;
  font-size: 20px;
  margin-left: 28px;
  text-align: left;
  padding: 10px;
  position: fixed;
  top: 5%;
  left: 5%;
  z-index: 1000;
`;

const Info = props => {

  const [work, setWork] = React.useState();

  const fetchWork = () => {
    fetch('/api/works/info/' + props.id)
      .then(response => response.json())
      .then(data => {
        setWork(data);
      });
    };
  React.useEffect(() => {
    fetchWork();
  }, []);

  return(
    <PopOver>
      <img src={`/aspected_thumb/${props.id}`}/>
      <br/>
      {work &&
        <span style={{width:400}}>
        {work.artist_name}, {work.artist_age}v, {work.work_country}
        <br/>
        "{work.work_title}", {work.work_year && work.work_year + ', '} {work.work_technique}
        <br/>
        {work.archive_collection_name}, {work.work_competition_category_and_placement}
        </span>
      }
    </PopOver>
  )
};

const Result = props => {
  const id = props.id;
  const {cart, setCart} = props.state;
  const [drag, setDrag] = React.useState(false);
  const posRef = props.posRef;
  const dragStart = (e: MouseEvent, data: Object) => {
      setDrag(true);
    };
  const dragEnd = (e: MouseEvent, data: Object) => {
      setDrag(false);
      var dX = e.clientX;
      var dY = e.clientY;
      var refs = posRef.current
      var found = -1;
      var minDist = Number.MAX_SAFE_INTEGER;
      Object.keys(refs).map(refId => {
        var rX = refs[refId].x;
        var rY = refs[refId].y;
        var dist = Math.sqrt((dX - rX)**2 + (dY - rY)**2);
        if (dist < minDist && refId !== id) {
          minDist = dist;
          found = refId;
        }
      })
      var cartCopy = JSON.parse(JSON.stringify(cart)).map(e => {
        if (e === id) return [];
        else if (e === found) return [id,found];
        else return e;
      }).flat();
      posRef.current={};
      setCart(cartCopy);
    };
  return (
  <Draggable
    disabled={!props.organizing}
    key={cart}
    onStart={dragStart}
    onStop={dragEnd}>
    <ResultContainer drag={drag} ref={el => {
        if (!el) return;
        posRef.current[id] = {id: id,
          x: el.getBoundingClientRect().x+el.getBoundingClientRect().width/2,
          y: el.getBoundingClientRect().y+el.getBoundingClientRect().height/2};
      }}>
      <ResultImg src={`/square_thumb/${id}`}/>
      <Inspect onMouseEnter={_ => props.setHover(id)}  onMouseLeave={_ => props.setHover(null)} to={`/work/` + id}/>
      {cart.includes(id) ?

        <Check in_cart={true} onClick={() => setCart(cart.filter((e) => e !== id))}/>
        :
        <Check in_cart={false} onClick={() => setCart([...cart, id])}/>
      }
    </ResultContainer>
   </Draggable>
  );
}


export const WorkGrid = props => {
  const works = props.works;
  const organizable = props.organizable || false;
  const [organizing, setOrganizing] = React.useState(false);
  const [hover, setHover] = React.useState();
  var posRef = React.useRef({});

  return (
    <React.Fragment>
      {hover && !organizing &&
        <Info id={hover}/>
      }
      {organizable &&
        <div style={{marginBottom: 10}}>
          Järjestelytila:
          <input
            type="checkbox"
            checked={organizing}
            onChange={e => setOrganizing(!organizing)} />
        </div>
      }
      {works.map(id =>
         <Result hover={hover} setHover={setHover} state={props.state} key={id} id={id} posRef={posRef} organizing={organizing}/>
      )}
    </React.Fragment>
  );
}
