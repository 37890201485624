import styled from "styled-components";
import React from 'react';
import {
  useParams
} from "react-router-dom";
var _ = require('lodash');

const WorkFields = [
  { name: 'work_title',                              translation: 'Teoksen nimi' },
  { name: 'work_technique',                          translation: 'Tekniikka' },
  { name: 'work_year',                               translation: 'Työn valmistumisvuosi' },
  { name: 'work_dimensions',                         translation: 'Koko' },
  { name: 'work_country',                            translation: 'Maa (englanniksi)' },
  { name: 'work_continent',                          translation: 'Manner' },
  { name: 'work_competition_category_and_placement', translation: 'Kilpailu' },
  { name: 'work_other_markings',                     translation: 'Muut merkinnät' },
  { name: 'work_signature_location',                 translation: 'Signeerauksen sijainti' },
  { name: 'archive_free_caption',                    translation: 'Vapaa Kuvaus' },
]
const ArtistFields = [
  { name: 'artist_name',                             translation: 'Nimi' },
  { name: 'artist_age',                              translation: 'Ikä' },
  { name: 'artist_gender',                           translation: 'Sukupuoli' },
  { name: 'artist_birthday',                         translation: 'Syntymäpäivä' },
  { name: 'artist_address',                          translation: 'Osoite' },
  { name: 'artist_workplace',                        translation: 'Työskentelypaikka' },
  { name: 'artist_workplace_address',                translation: 'Työskentelypaikan osoite' },
  { name: 'artist_training_duration',                translation: 'Osallistuminen ohjattuun työskentelyyn' },
  { name: 'artist_instruction_group_size',           translation: 'Ohjatun ryhmän koko' },
  { name: 'artist_instructor_training_and_name',     translation: 'Ohjaajien nimi ja koulutus' },
  { name: 'artist_instructor_count',                 translation: 'Ohjaajien lukumäärä' },
]
const ArchiveFields = [
  { name: 'archive_archival_number',                 translation: 'Arkistointinumero' },
  { name: 'archive_location_category',               translation: 'Säilytyspaikan tunnus' },
  { name: 'archive_archivalist_name',                translation: 'Arkistoijan nimi' },
  { name: 'archive_notes',                           translation: 'Lisätiedot' },
  { name: 'archive_date_of_archival',                translation: 'Arkistointipäivä' },
  { name: 'archive_temporary_location',              translation: 'Väliaikainen sijoituspaikka' },
  { name: 'archive_earlier_locations',               translation: 'Sijoituspaikkojen historia' },
  { name: 'archive_storage_unit',                    translation: 'Vakinainen sijoituspaikka' },
  { name: 'archive_collection_name',                 translation: 'Kokoelman nimi' },
  { name: 'archive_exhibit_history',                 translation: 'Näyttelyhistoria' },
]


const Page = styled.div`
  padding: 14pt;
  background-color: #eeeeee;
  font-family: Helvetica;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 820px;
  margin: 2pt;
`
const Image = styled.img`
  margin: 10pt;
  border: 2pt solid #111111;
  max-width: 800px;
`
const SectionContainer = styled.div`
  font-size: 25pt;
  vertical-align: text-top;
  width: 500px;
  display: inline-block;
  background-color: lightgray;
  border-radius: 3px;
  margin: 2pt;
  padding-bottom: 1pt;
`

const SectionHeader = styled.div`
  font-variant: small-caps;
  color: #444444;
  padding: 10pt;
`


const FieldContainer = styled.div`
  max-width: 500px;
  background-color: lightgray;
  border: 1px solid darkgray;
  border-radius: 3px;
  margin: 2pt;
`

const FieldHeader = styled.div`
  color: #666666;
  font-size: 8pt;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4pt 0pt 0pt 4pt;
`
const FieldValue = styled.div`
  display: block;
  overflow: hidden;
  margin: 4pt;
`
const FieldInput = styled.input`
  color: #444444;
  font-size: 12pt;
  margin: 7pt;
  padding: 4pt;
  border: 1px solid darkgray;
  border-radius: 3px;
  background-color: #dddddd;
  width: 94%;
  font-family: Helvetica;
`

const FieldTextArea = styled.textarea`
  color: #444444;
  font-size: 12pt;
  margin: 7pt;
  padding: 4pt;
  border: 1px solid darkgray;
  border-radius: 3px;
  background-color: #dddddd;
  width: 94%;
  height: 250px;
  font-family: Helvetica;
`

const FieldSelect = styled.select`
  color: #444444;
  font-size: 12pt;
  margin: 7pt;
  padding: 4pt;
  border: 1px solid darkgray;
  border-radius: 3px;
  background-color: #dddddd;
  font-family: Helvetica;
`

const DeleteLink = styled.a`
  color: #cc4444;
  font-size: 12pt;
  padding: 4pt;
`
const Tag = styled.div`
  margin-left: 8pt;
  font-size: 25pt;
`

const Tags = props => {
  const workId = props.workId;
  const [tags, setTags] = React.useState([]);
  const [newTagName, setNewTagName] = React.useState("");
  const write_permission = props.write_permission;
  const fetchTags = () => {
    fetch('/api/works/tags/for_work/' + workId)
      .then(response => response.json())
      .then(data => {
        setTags(data);
      });
    };
  const createTag = () => {
    fetch('/api/works/tags/create?name=' + newTagName + '&work_id=' + workId)
      .then(response => response.json())
      .then(data => {
        fetchTags();
        setNewTagName("");
      });
    };
  const deleteTag = (tagId) => {
    fetch('/api/works/tags/delete?work_id=' + workId + '&tag_id=' + tagId)
      .then(response => response.json())
      .then(data => {
        fetchTags();
      });
    };
  React.useEffect(() => {
    fetchTags();
  }, []);
  return (
     <>
       {tags.map(t =>
         <Tag key={t}>
           #{t.name} {write_permission && <button onClick={() => deleteTag(t.tag_id)}>❌</button>}
         </Tag>
       )}
       {write_permission && <>
       <input value={newTagName}
              onChange={e => setNewTagName(e.target.value)}
              onKeyDown={e => {
                          if (e.key === 'Enter') {
                            createTag();
                          }
                        }}/>
       <button onClick={() => createTag()}>➕ Lisää asiasana</button>
       </>}
     </>
  )
}

const Field = props => {
  const {name , value, translation, setWorkData, workData} = props;
  const write_permission = props.write_permission;
  const onFieldChange = value => {
    if (!write_permission) return;
    var temp = _.cloneDeep(workData);
    temp[name] = value;
    setWorkData(temp);
  }
  const field = () => {
    if (name === 'archive_free_caption' || name === 'archive_notes') {
      return <FieldTextArea value={value} onChange={e => onFieldChange(e.target.value)}/>
    } else if (name === 'archive_location_category') {
      return (<FieldSelect value={value} onChange={e => onFieldChange(e.target.value)}>
                <option value=''>-</option>
                <option value='A'>A</option>
                <option value='B'>B</option>
                <option value='C'>C</option>
                <option value='D'>D</option>
              </FieldSelect>
      )
    } else {
      return <FieldInput value={value} onChange={e => onFieldChange(e.target.value)}/>
    }
  }
  return (
    <FieldContainer>
      <FieldHeader>
        {translation}
      </FieldHeader>
      <FieldValue>
        {field()}
      </FieldValue>
    </FieldContainer>
  )
}

const FieldsFor = props => {
  let { fields, workData, setWorkData } = props;
  const write_permission = props.write_permission;
  return(
    <React.Fragment>
      {fields.map(d =>
        <React.Fragment>
        {d.name in workData ?
          <Field key={d} name={d.name} value={workData[d.name]} translation={d.translation} workData={workData} setWorkData={setWorkData} write_permission={write_permission}/>
          :
          <React.Fragment/>
        }
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export const Work = props => {
  let { workId } = useParams();
  const [workData, setWorkData] = React.useState({});
  const [unalteredWorkData, setUnalteredWorkData] = React.useState({});
  const write_permission = props.state.permissions.write

  const fetchWorkData = () => {
    fetch('/api/works/info/' + workId)
      .then(response => response.json())
      .then(data => {
        setWorkData(data);
        setUnalteredWorkData(data);
      });
    };

  const alterWork = () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(workData)
    };
    console.log(requestOptions);
    fetch('/api/works/alter', requestOptions)
        .then(response => response.json())
        .then(data => {
          fetchWorkData();
        })
  };
  React.useEffect(() => {
    fetchWorkData();
  }, []);
  const SaveButtonMaybe = () => {
    return (!_.isEqual(workData,unalteredWorkData) && write_permission ? <button onClick={() => alterWork()}>Tallenna muutokset</button> : '')
  }
  return (
    <Page>
      <ImageContainer>
        <Image src={`/img/${workId}`}/>
      </ImageContainer>
      <SectionContainer>
        <SaveButtonMaybe/>
        <SectionHeader>Teoksen tiedot</SectionHeader>
        <FieldsFor fields={WorkFields} workData={workData} setWorkData={setWorkData} write_permission={write_permission}/>
      </SectionContainer>
      <SectionContainer>
        <SaveButtonMaybe/>
        <SectionHeader>Tekijän tiedot</SectionHeader>
        <FieldsFor fields={ArtistFields} workData={workData} setWorkData={setWorkData} write_permission={write_permission}/>
      </SectionContainer>
      <SectionContainer>
        <SaveButtonMaybe/>
        <SectionHeader>Arkistointitiedot</SectionHeader>
        <FieldsFor fields={ArchiveFields} workData={workData} setWorkData={setWorkData} write_permission={write_permission}/>
      </SectionContainer>
      <SectionContainer>
        <SaveButtonMaybe/>
        <SectionHeader>Asiasanat</SectionHeader>
        <Tags workId={workId} write_permission={write_permission}/>
      </SectionContainer>
      {write_permission &&
      <SectionContainer>
        <DeleteLink href={`/delete_confirmation/${workId}`}>
          Poista arkistomerkintä (kysyy varmistuksen) 🗑️
        </DeleteLink>
      </SectionContainer>
      }
    </Page>
  );
}
