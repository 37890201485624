import styled from "styled-components";
import React from 'react';
import { VerifyAction } from './verifyAction.js';

const ExContainer = styled.div`
`;
const TextAreaInput = styled.textarea`
  font-size: 13pt;
  min-width: 80%;
  min-height: 300px;
  resize: both;
`;
const Select = styled.select`
  font-size: 13pt;
  min-width: 80%;
`;
const ExhibitionRow = styled.div`
  margin-top: 10pt;
`;
const ExTitle = styled.span`
  font-size: 14pt;
  &:hover {
    text-shadow: 0px 0px 20px #cc00ff;
    cursor: pointer;
  }
`;
const ExOptions = styled.div`
  font-size: 12pt;
  margin: 15px;
  padding: 5px;
  width: 100%;
`;

const TextInput = styled.input`
  font-size: 13pt;
  min-width: 80%;
`;

const ExRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: .5em;
`;

const ExLabel = styled.label`
  flex: 0 0 200px;
`;

const Page = styled.div`
  padding: 20pt;
`;

export const Exhibitions = props => {

  const [exhibitions, setExhibitions] = React.useState([]);
  const [exhibitionCategories, setExhibitionCategories] = React.useState([]);
  const [baskets, setBaskets] = React.useState([]);

  const fetchExhibitions = () => {
    fetch('/api/exhibitions/list')
      .then(response => response.json())
      .then(data => {
        setExhibitions(data);
      });
    };
  const fetchBaskets = () => {
    fetch('/api/baskets/list')
      .then(response => response.json())
      .then(data => {
        setBaskets(data);
      });
    };

  const fetchExhibitionCategories = () => {
    fetch('/api/exhibitions/exhibition_categories/list')
      .then(response => response.json())
      .then(data => {
        setExhibitionCategories(data);
      });
    };
  const createExhibition = () => {
    fetch('/api/exhibitions/create')
      .then(response => response.json())
      .then(data => {
        fetchExhibitions();
      });
    };
  React.useEffect(() => {
    fetchExhibitions();
    fetchExhibitionCategories();
    fetchBaskets();
  }, []);

  return (
    <Page>
      <h2>Näyttelyt</h2>
      <button onClick={() => createExhibition()}>➕ Uusi näyttely</button>
      {exhibitions.map(e => <ExhibitionRow key={e.exhibition_id}><Exhibition key={e.exhibition_id} exhibition={e} baskets={baskets} exhibitionCategories={exhibitionCategories} f={fetchExhibitions}/></ExhibitionRow> )}
    </Page>
  );
}


  // <div style={{margin: 20}}>
  // <p style={{maxWidth: 500}} >
  //   Tällä sivulla voit luoda näyttelyitä ja muokata näyttelyiden tietoja.
  //   Näyttelyt esikatselu -toiminnosta näet millaisena näyttelyt verkkosivuilla julkisina.
  // </p>
  // <p style={{maxWidth: 500}} >
  //   📢 -symboli näyttelyn nimen perässä tarkoittaa, että se on julkinen.
  // </p>
  // </div>

const Exhibition = (props) => {
  const id = props.exhibition.exhibition_id;
  const [title, setTitle] = React.useState(props.exhibition.exhibition_title);
  const [publicBool, setPublicBool] = React.useState(props.exhibition.public);
  const [text, setText] = React.useState(props.exhibition.exhibition_text);
  const [basketId, setBasketId] = React.useState(props.exhibition.basket_id);
  const [exhibitionCategoryId, setExhibitionCategoryId] = React.useState(props.exhibition.exhibition_category_id);
  const [editing, setEditing] = React.useState(false);

  const baskets = props.baskets
  const exhibitionCategories = props.exhibitionCategories
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleBasketIdChange = (event) => {
    setBasketId(event.target.value);
  };
  const handleExhibitionCategoryChange = (event) => {
    setExhibitionCategoryId(event.target.value);
  };
  const handlePublicBoolChange = (event) => {
    setPublicBool(event.target.value);
  };
  const apiRequest = (address) => {
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ exhibition_id: id, exhibition_title: title, exhibition_text: text, basket_id: basketId, exhibition_category_id: exhibitionCategoryId, public: publicBool})
			};
		fetch(address, requestOptions)
				.then(async response => {
          var data = await response.json()
          if (!response.ok) {
            const error = (data && data.message) || response.status;
					  return Promise.reject(error);
          }
          return data})
				.then(data => {
          props.f()
				})
        .catch(error => {
          console.error('API error', error);
        });
  }
  const saveChanges = (event) => {
			apiRequest('/api/exhibitions/update');
  }
  const deleteExhibition = (event) => {
			apiRequest('/api/exhibitions/delete');
  }
  const clean = () => {
    return title === props.exhibition.exhibition_title &&
    text === props.exhibition.exhibition_text &&
    basketId === props.exhibition.basket_id &&
    exhibitionCategoryId === props.exhibition.exhibition_category_id &&
    publicBool.toString() === props.exhibition.public.toString();
  }
  return (
    <ExContainer>
      <ExTitle><a onClick={() => setEditing(!editing)}>{title}{publicBool ? ' 📢' : ' 🥷'}</a></ExTitle>
      {editing &&
      <ExOptions>
        <ExRow>
          <ExLabel>Näyttelyn nimi:</ExLabel>
          <TextInput type="text" value={title} onChange={handleTitleChange}/>
        </ExRow>
        <ExRow>
          <ExLabel>Näyttelyn kuvaus:</ExLabel>
          <TextAreaInput value={text} onChange={handleTextChange}/>
        </ExRow>
        <ExRow>
          <ExLabel>Kori:</ExLabel>
          <Select value={basketId} onChange={handleBasketIdChange}>
            {baskets.map(t => <option key={t.basket_id} value={t.basket_id}>{t.name}</option>)}
          </Select>
        </ExRow>
        <ExRow>
          <ExLabel>Näyttelykategoria:</ExLabel>
          <Select value={exhibitionCategoryId} onChange={handleExhibitionCategoryChange}>
            {exhibitionCategories.map(t => <option key={t.exhibition_category_id} value={t.exhibition_category_id}>{t.exhibition_category_name}</option>)}
          </Select>
        </ExRow>
        <ExRow>
          <ExLabel>Näkyvyys:</ExLabel>
          <Select value={publicBool} onChange={handlePublicBoolChange}>
            <option value={true}>Julkinen</option>
            <option value={false}>Piilotettu</option>
          </Select>
        </ExRow>
        <ExRow>
        {!clean() && <button onClick={saveChanges}>💾 tallenna muutokset</button>}
        <VerifyAction action={deleteExhibition} text="❌ Poista"/>
        </ExRow>
      </ExOptions>
      }
    </ExContainer>
  );
}
