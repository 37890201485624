import styled from "styled-components";
import React from 'react';

const Input = styled.input`
  font-size: 13pt;
`;
const Label = styled.label`
  flex: 0 0 200px;
  text-align: right;
  margin-right: 20px;
`;

const Button = styled.button`
  display: block;
  font-size: 13pt;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: .5em;
`;

const Form = styled.form`
`;

export const User = props => {

  const [error, setError] = React.useState()
  const [success, setSuccess] = React.useState()


  const submit = async e => {
    e.preventDefault();
    setError(null)
    setSuccess(null)
    const oldPassword = e.target.oldPassword.value;
    const newPassword = e.target.newPassword.value;
    const newPasswordAgain = e.target.newPasswordAgain.value;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({oldPassword: oldPassword, newPassword: newPassword, newPasswordAgain: newPasswordAgain})
    };
    const res = await fetch('/api/user/change_password', requestOptions);
    const result = await res.json();
    const message = result.message;
    if (!res.ok) setError(message);
    else setSuccess(message);

  }
  return (
    <Form onSubmit={submit}>
      <Row>
        <Label>Vanha salasana:</Label>
        <Input type="password" name="oldPassword"/>
      </Row>
      <Row>
        <Label>Uusi salasana:</Label>
        <Input type="password" name="newPassword"/>
      </Row>
      <Row>
        <Label>Uusi salasana uudestaan:</Label>
        <Input type="password" name="newPasswordAgain"/>
      </Row>
      {error &&
      <Row style={{color: 'red'}}>
        <Label></Label>
        {error}
      </Row>
      }
      {success &&
      <Row style={{color: 'green'}}>
        <Label></Label>
        {success}
      </Row>
      }
      <Row>
        <Label></Label>
        <Button type="submit">Vaihda salasana</Button>
      </Row>
      <Row>
        <Label></Label>
        <p>write_permission: {props.state.permissions.write ? 'true' : 'false'}</p>
      </Row>
    </Form>
  );
};
