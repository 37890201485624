import React from 'react';


export const VerifyAction = props => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <React.Fragment>
    {!expanded ?
      <span style={{cursor: 'pointer'}} onClick={_ => setExpanded(true)}>{props.text}</span>
      :
      <span>
        Oletko varma ?
        <span style={{cursor: 'pointer'}} onClick={_ => {props.action(); setExpanded(false)}}> ✔️ kyllä </span>
        <span style={{cursor: 'pointer'}} onClick={_ => setExpanded(false)}> ❌ ei </span>
      </span>
    }
    </React.Fragment>
  );
}
