import styled from "styled-components";
import React from 'react';
import { VerifyAction } from './verifyAction.js';
import { Link } from "react-router-dom";
var _ = require('lodash');

const BasketOptions = styled.div`
  margin: 15px;
  margin-left: 25px;
  border: 1px solid #999966;
  background-color: #dddddd;
  max-width: 600px;
  border-radius: 5px;
  padding-left: 20pt;
`;
const BasketTitle = styled.span`
  font-weight: normal;
  margin: 2pt;
  font-size: 14pt;
  &:hover {
    text-shadow: 0px 0px 20px #cc00ff;
    cursor: pointer;
  }
`;

const BasketRow = styled.span`
  padding: 2pt;
`;

const WorkBasketOptions = styled.div`
  margin: 20px 0px 20px 0px;
`;

const Page = styled.div`
  padding: 20pt;
`;

const ViewLink = styled(Link)`
  color: #333333;
  text-decoration: none;
`;


export const Baskets = props => {

  const [baskets, setBaskets] = React.useState([]);
  const [newBasketName, setNewBasketName] = React.useState("");
  const {cart, setCart} = props.state;
  const [filter, setFilter] = React.useState("");

  const fetchBaskets = () => {
    fetch('/api/baskets/list')
      .then(response => response.json())
      .then(data => {
        setBaskets(data);
      });
    };
  const createBasket = () => {
    fetch('/api/baskets/create?name=' + newBasketName + '&works=' + cart.join(','))
      .then(response => response.json())
      .then(data => {
        fetchBaskets();
      });
    };
  React.useEffect(() => {
    fetchBaskets();
  }, []);
  return (
    <Page>
      <h2>Työkori ({cart.length} työtä):</h2>
      <WorkBasketOptions>
      <input type='text' onChange={(e) => setNewBasketName(e.target.value)} placeholder='Uuden korin nimi'></input>
      <button onClick={() => createBasket()}>➕ Tallenna työkori</button>
      <br/>
      <br/>
      <VerifyAction action={e => setCart([])} text="🗑️ tyhjennä työkori ❌" />
      </WorkBasketOptions>
      <br/>
      <h2>Tallennetut korit:</h2>
      <input type='text' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder='suodata koreja'></input><br/>
      {baskets.filter(b => b.name.search(filter) !== -1).map(b => <BasketRow key={b.basket_id}><Basket state={props.state} key={b.basket_id} basket={b} f={fetchBaskets}/></BasketRow> )}
    </Page>
  );
}

const Basket = (props) => {
  const id = props.basket.basket_id;
  const [name, setName] = React.useState(props.basket.name);
  const [expanded, setExpanded] = React.useState(false);
  const {cart, setCart, permissions} = props.state;

  const deleteBasket = () => {
    fetch('/api/baskets/delete?id=' + id)
      .then(response => response.json())
      .then(data => {
        props.f();
      });
    };

  const emptyBasket = () => {
    fetch('/api/baskets/empty?id=' + id)
      .then(response => response.json())
      .then(data => {
        props.f();
      });
    };

  const saveBasket = () => {
    fetch('/api/baskets/save?id=' + id + '&works=' + cart.join(','))
      .then(response => response.json())
      .then(data => {
        props.f();
      });
    };

  const loadBasket = () => {
    fetch('/api/baskets/load?id=' + id)
      .then(response => response.json())
      .then(data => {
        setCart(_.union(cart,data));
      });
    };

  return (
    <div>
      <BasketTitle onClick={() => setExpanded(!expanded)}> {name}</BasketTitle> ({props.basket.work_count}) ({props.basket.exhibition_count})
      {expanded &&
        <BasketOptions>
            <p>Korissa on {props.basket.work_count} työtä.</p>
            {permissions.write && <span style={{cursor: 'pointer'}} onClick={() => saveBasket()}><p>➕ Lisää työkorin sisältö tähän koriin</p></span>}
            <span style={{cursor: 'pointer'}} onClick={() => loadBasket()}><p>📖 Lisää tämän korin työt työkoriin</p></span>
            <ViewLink to={`/viewbasket/` + id}><p>👀 Tarkastele korin sisältöä</p></ViewLink>
            {permissions.write && <p><VerifyAction action={emptyBasket} text="🗑️ tyhjennä"/></p>}
            {permissions.write && <p><VerifyAction action={deleteBasket} text="❌ poista"/></p>}
            {permissions.write && <p>Näyttelyt jotka käyttävät koria: {props.basket.exhibition_names}</p>}
        </BasketOptions>
      }
    </div>
  );
}
